/* eslint-disable indent */
/* eslint-disable callback-return */
import { createRouter, createWebHashHistory } from 'vue-router';
import store from '@/store';
import { ElLoading } from 'element-plus';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const routes = [
  {
    path: '/register',
    name: 'register',
    meta: { title: '用户注册' },
    component: () => import('@/views/register/Index.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: '登录页面' },
    component: () => import('@/views/login/Index.vue')
  },
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'home',
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () => import('@/home/Index.vue'),
    children: [
      {
        path: '/404',
        name: '404',
        meta: { title: '404', isHide: true },
        component: () => import('@/views/error/404.vue')
      }
    ]
  }
],

  //创建路由
  router = createRouter({
    history: createWebHashHistory(),
    base: process.env.NODE_ENV === "production" ? "/" : "/", // 公共路径
    routes: routes
  });
// 子组件静态路由懒加载

// eslint-disable-next-line one-var
export const loadView = (view) => {
  return (resolve) => require([`@/views${view}/Index.vue`], resolve);
};
/** 动态注册路由 */
function asyncRouter (data = []) {
  if (data && data.length > 0) {
    data.forEach(menu => {
      if (menu.parentId !== '0') {
        menu.component = loadView(menu.url);
        menu.meta = { title: menu.name, icon: menu.icon, permission: menu.button };
        const children = {
          path: menu.url,
          name: menu.name,
          meta: menu.meta,
          // component: () => loadView(menu.url)
          component: () => import('@/views' + menu.url + '/Index.vue')
        };

        router.addRoute("home", children);
      }
      if (menu.hasChildren) {
        menu.children = asyncRouter(menu.children);
      }
    });
  }
  return data;
}

NProgress.configure({ showSpinner: false });


router.beforeEach(async (to, from, next) => {
  // 设置标题
  document.title = to.meta.title;
  const menuList = store.getters.menuList;
  // 如果已经登录，就进入首页

  if (menuList) {
    const name = to.name;
    //没有生成动态路由 或者路由不存在

    if (name && router.hasRoute(name)) {
      next();
    } else {
      try {
        //生成动态路由
        asyncRouter(menuList);
        next({ ...to, replace: true });
      } catch (error) {
        // 移除token重新登录
        await store.dispatch('user/resetToken');
        next(`/login?redirect=${to.path}`);
      }
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (to.name === 'login' || to.name === 'register') {
      next();
    } else {
      next(`/login?redirect=${to.path}`);
    }

  }

});

router.afterEach(() => {
  // 进度条结束
  NProgress.done();
});
NProgress.configure({ ease: 'linear', speed: 500 });

export default router;